const config = {
	signIn: {
		domain: 'statvis-ax-user-pool-domain-prod.auth.eu-west-1.amazoncognito.com',
		clientId: '5bagq7r5kfnqm9tmeg7ujjm7qo',
		redirectURI: 'https://statvis.tools.qax.io/',
	},
	userPool: 'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_onIVQVJtb',
	identityPoolId: 'eu-west-1:57268595-e095-412e-b03f-eabc09d50c23',
	primaryAccountName: 'tooling',
	secondaryAccounts: [
		{
			accountId: '519548423067',
			accountName: 'bd',
			rolePath: 'cdk/eu-west-1/statvis-client-bd-eu-west-1/',
			roleName: 'statvis-client-bd-eu-west-StatvisAssumeableRole97D-16IQSRNE27HI2',
		},
		{
			accountId: '816114512900',
			accountName: 'bi',
			rolePath: 'cdk/eu-west-1/statvis-client-bi-eu-west-1/',
			roleName: 'statvis-client-bi-eu-west-StatvisAssumeableRole97D-XGM3GTDH0SR2',
		},
		{
			accountId: '862324887326',
			accountName: 'mgmt',
			rolePath: 'cdk/eu-west-1/statvis-client-mgmt-eu-west-1/',
			roleName: 'statvis-client-mgmt-eu-we-StatvisAssumeableRole97D-YBIL9NELPFYO',
		},
		{
			accountId: '161280625996',
			accountName: 'nlgprod',
			rolePath: 'cdk/eu-west-1/statvis-client-nlgprod-eu-west-1/',
			roleName: 'statvis-client-nlgprod-eu-StatvisAssumeableRole97D-1DNLEWQ9C9S98',
		},
		{
			accountId: '813573720983',
			accountName: 'nlgstage',
			rolePath: 'cdk/eu-west-1/statvis-client-nlgstage-eu-west-1/',
			roleName: 'statvis-client-nlgstage-e-StatvisAssumeableRole97D-NHBREIGNTJB6',
		},
		{
			accountId: '961470573334',
			accountName: 'office',
			rolePath: 'cdk/eu-west-1/statvis-client-office-eu-west-1/',
			roleName: 'statvis-client-office-eu-StatvisAssumeableRole97D-SCIM1R9M8HD2',
		},
		{
			accountId: '237388157798',
			accountName: 'research',
			rolePath: 'cdk/eu-west-1/statvis-client-research-eu-west-1/',
			roleName: 'statvis-client-research-e-StatvisAssumeableRole97D-1RAK3KEI74GYV',
		},
		{
			accountId: '133883293548',
			accountName: 'sales',
			rolePath: 'cdk/eu-west-1/statvis-client-sales-eu-west-1/',
			roleName: 'statvis-client-sales-eu-w-StatvisAssumeableRole97D-193GX81397CZU',
		},
		{
			accountId: '522436745425',
			accountName: 'salesstage',
			rolePath: 'cdk/eu-west-1/statvis-client-salesstage-eu-west-1/',
			roleName: 'statvis-client-salesstage-StatvisAssumeableRole97D-8WHZTOMG8PJ5',
		},
		{
			accountId: '080427932363',
			accountName: 'mfaexperiments',
			rolePath: 'cdk/eu-west-1/statvis-client-mfaexperiments-eu-west-1/',
			roleName: 'statvis-client-mfaexperim-StatvisAssumeableRole97D-1PHT9V0IBBTVI',
		},
	],
}

export default config
